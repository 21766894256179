import React from 'react';
import DeviceAuth from '@tra-sg/gatsby-theme-c360-portal/src/components/DeviceAuth'
import PageWrapper from '@tra-sg/gatsby-theme-c360-portal/src/components/PageWrapper';
import { Location } from "@reach/router";

export default function LabPageGallery({}) {
  return (
    <PageWrapper
      activeTab="datalake-page"
      metaTitle="Datasets | c360"
    >
      <Location>
      {({ location }) => (<DeviceAuth location={location}/>)}
      </Location>
    </PageWrapper>
  )
}
