import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { deviceAuth } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';
import queryString from 'query-string';


function getCodeFromQueryString() {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const queryToken = url.split("?");
    if (queryToken.length > 1) {
      const pageQuery = queryString.parse(queryToken[1]);
      console.log("PAGEQUERY", pageQuery)
      if (pageQuery.code) {
        return pageQuery.code;
      }
    }
    return null;
}

function DeviceAuthResult({ code, mutation }) {
    const { isLoading, isError, data, error, isIdle } = mutation;

    if (isLoading) {
        return (
            <div>
                <p>Waiting to authorize: {code}</p>
                <progress class="progress is-primary" max="100">30%</progress>
            </div>
        )
    } else if (isError) {
        console.log("Error", error);
        return (
            <div>
                <p>Error authorizing: {code}</p>
                <p>{`${error.message}`}</p>
            </div>
        )
    } else if (isIdle) {
        return "";
    } else {
        return (
            <div>
                <p>Authorization next step: {code}</p>
                <p>
                    You will be redirected to the final step of the authorization process.
                    If you are not redirected within 5 seconds, you can manually go to the
                    following link:

                </p>
                <p><a href={data.location}>{data.location}</a></p>
            </div>
        )
    }

}

export default function DeviceAuth({ location }) {
    const code_from_querystring = getCodeFromQueryString();
    const [authCode, setAuthCode] = useState(code_from_querystring);

    const mutation = useMutation(
        async (code) =>  await deviceAuth(code),
        {
            onSuccess: (data) => {
                window.location.href = data.location;
            }
        }
    )

    useEffect(() => {
        if (code_from_querystring > "" && mutation.isIdle) {
            mutation.reset();
            mutation.mutate(code_from_querystring);
        }
    })

    return (
        <div className='container content'>
            <h1 className='title'>Device Authorization</h1>
            <p>
                Authorize a client (such as `c360-python-client`) to access data on
                c360.ai on your behalf.
            </p>
            <div className="field has-addons">
                <div className="control">
                    <input
                        class="input" type="text" placeholder="Device Code"
                        value={authCode} onChange={function (e) { setAuthCode(e.target.value); } }
                    />
                </div>
                <div class="control">
                    <a class="button is-info" onClick={() => mutation.mutate(authCode)}>
                    Authorize
                    </a>
                </div>
            </div>
            <DeviceAuthResult code={authCode} mutation={mutation}/>
        </div>
    )
}
